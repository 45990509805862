<template>
  <div>
    <div v-if="!isLoading" class="mx-">
      <survey :survey="survey" />
    </div>
    <div v-else>Loading...</div>
  </div>
</template>

<script>
import { StylesManager, Model } from "survey-vue";
import "survey-vue/defaultV2.css";

StylesManager.applyTheme("defaultV2");

const filesRoot = process.env.VUE_APP_FILES_ROOT;

export default {
  name: "SurveyResponseView",

  async mounted() {
    this.isLoading = true;
    let surveyId = this.$route.params.surveyId;

    await this.$store.dispatch("loadSurveyPayload", surveyId);

    await this.$store.dispatch("fetchSurveyByUid",surveyId);

    let surveyContent = await this.fetchSurveyPayload(
      filesRoot + this.$store.getters.getSurveyPayload
    );

    localStorage.setItem("surveyContent",surveyContent);

    this.isLoading = false;
    this.data = JSON.parse(surveyContent);
    this.survey = new Model(this.data);
    this.survey.showCompletedPage = true;
  


    this.survey.addNavigationItem({
      title: "Go Back",
      action:()=> this.goBack(-1)
    });
    
    this.survey.onComplete.add(this.surveyComplete);
    

  },

  data() {
    return {
      survey: null,
      isLoading: true,
    };
  },
  components: {
  },

  methods: {
    async fetchSurveyPayload(url) {
      let response = await fetch(url);
      let data = response.text();

      return data;
    },
    goBack(){
      this.$router.go(-1);
    },

    surveyComplete(sender) {

      var data = {
        surveyAnswers: sender.data,
        surveyPayloadUrl: this.$store.getters.getSurveyPayload,
      };

      this.$store.commit("setQuizAnswers", sender.data);
      this.$store.dispatch("postSurveyResponse", data);

      const surveyType = this.$store.getters.getSurveyByUid.surveyType;

      if(surveyType == 'quiz'){
        setTimeout(()=>{
          this.$router.push("/quizes/preview_quiz_answers/" + this.$route.params.surveyId);

        }, 3000)
      }
      else if(surveyType == 'survey'){
          // this.$router.push('/');
          this.$router.go(-1);
      }

    }
  },
};
</script>

